import React from "react";
import { graphql } from "gatsby";
import PortableText from "react-portable-text";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import NewsLetters from "../components/common/newsletter";
import Seo from "../components/common/SEO";

export default function ShippingPolicy({ data: { sanityShippingPolicy } }) {
  return (
    <Layout>
      <Seo
        title={sanityShippingPolicy?.seo?.metaTitle}
        description={sanityShippingPolicy?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading title={sanityShippingPolicy?.title} />
            <div>
              <div className="mt-12">
                <PortableText
                  content={sanityShippingPolicy?._rawContent}
                  className="px-4 block-content max-w-none md:px-0"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[80px]">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    sanityShippingPolicy {
      title
      _rawContent
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
